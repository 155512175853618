import {FETCH_OBJECTS_ERROR, FETCH_OBJECTS_START, FETCH_OBJECTS_SUCCESS} from "../actions/actionTypes";

const initialState = {
    objects: [],
    redirect: false,
    loading: false,
    error: null
}

export default function objectsReducer(state = initialState, action){
    switch (action.type) {
        case FETCH_OBJECTS_START :
            return {
                ...state, loading: true
            }
        case FETCH_OBJECTS_SUCCESS :
            return {
                ...state, loading: false, objects: action.objects
            }
        case FETCH_OBJECTS_ERROR :
            return {
                ...state, loading: false, error: action.error
            }
        default:
            return state
    }
}