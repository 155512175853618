import React from 'react'
import classes from './OnOffSwitcher.css'

const OnOffSwitcher = props => {

    const htmlFor = `checkbox-${Math.random()}`

    return (
        <div className={classes.OnOffSwitcher}>
            <label htmlFor={htmlFor}>{props.label}</label>
            <label className={classes.switch}>
                <input
                    type="checkbox"
                    id={htmlFor}
                    checked={props.value}
                    onChange={props.onChange}/>
                <span className={classes.slider+' '+classes.round}></span>
            </label>
        </div>
    )
}

export default OnOffSwitcher