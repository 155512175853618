import React, {Component} from 'react'

import API from "../../axios/API"
import ObjectForm from "../../components/Forms/ObjectForm/ObjectForm";

class ObjectAdd extends Component {

    state = {
        loading: false
    }

    saveObject = (controls) => {

        this.setState({loading:true})

        API.saveObject(
            null
            ,controls.alias.value
            ,controls.title.value
            ,controls.db_host.value
            ,controls.db_port.value
            ,controls.db_events_path.value
            ,controls.db_photos_path.value
            ,controls.db_backups_path.value
            ,controls.db_user.value
            ,controls.db_password.value
            ,controls.in_shkola_username.value
            ,controls.in_shkola_password.value
            ,controls.users_sync_period.value
            ,controls.events_sync_period.value
            ,controls.access_schema_name.value
            ,controls.last_event_id.value
            ,controls.controllers_sync_period.value
        ).then((responce) => {
			this.setState({loading:false})
            if(responce.data.status === 'ok') {
                this.props.history.push('/objects/view/'+controls.alias.value)
            } else {
                alert(responce.data.text)
            }
        }).catch(error=>{
			this.setState({loading:false})
        })
    }

    render() {
        return (
            <>
                <h1>Добавление объекта</h1>
                <ObjectForm
                    onSaveHandler={(controls)=>{this.saveObject(controls)}}
                    loading={this.state.loading}
                />
            </>
        )
    }
}

export default ObjectAdd