export const AUTH_BEGIN = 'AUTH_BEGIN'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_LOGGED_IN = 'SET_LOGGED_IN'

export const FETCH_OBJECTS_START = 'FETCH_OBJECTS_START'
export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS'
export const FETCH_OBJECTS_ERROR = 'FETCH_OBJECTS_ERROR'

export const FETCH_OBJECT_START = 'FETCH_OBJECT_START'
export const FETCH_OBJECT_SUCCESS = 'FETCH_OBJECT_SUCCESS'
export const FETCH_OBJECT_ERROR = 'FETCH_OBJECT_ERROR'

// dashboard container
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR'

// control container
export const RESTART_SERVICE_BEGIN = 'RESTART_SERVICE_BEGIN'
export const RESTART_SERVICE_END = 'RESTART_SERVICE_END'

export const SET_CONTROLLER_LIMIT = 'SET_CONTROLLER_LIMIT'
