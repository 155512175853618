import React from 'react'
import classes from './Navigation.css'
import {NavLink} from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs} from '@fortawesome/free-solid-svg-icons'

const Navigation = props => {

    const links = [
        {title:'Главная',href:'/', exact: true},
        {title:'Объекты',href:'/objects/online', exact: false},
        {title:'Управление',href:'/control', exact: false},
        {title:(<FontAwesomeIcon icon={faCogs} title="Переполнение контроллера" />),href:'/settings', exact: false}
    ]

    function renderLinks(){
        return links.map((link,index)=>{
            return (
                <li key={index}>
                    <NavLink
                        to={link.href}
                        exact={link.exact}
                        activeClassName={classes.active}>
                        {link.title}
                    </NavLink>
                </li>
            )
        })
    }

    return (
        <nav className={classes.Navigation}>
            <ul>
                {renderLinks()}
            </ul>
        </nav>
    )
}

export default Navigation