import React, {Component} from 'react'
import classes from './Auth.css'
import Button from '../../components/UI/Button/Button'
import Input from '../../components/UI/Input/Input'
import is from 'is_js'
import {connect} from "react-redux"
import {auth,setGeneralErrorMessage} from "../../store/actions/auth"

class Auth extends Component {

  state = {
    isFormValid: false,
    formControls: {
      email: {
        value: localStorage.getItem('email') ?? '',
        type: 'email',
        label: 'Email',
        errorMessage: 'Введите корректный email',
        valid: true,
        touched: false,
        validation: {
          required: true,
          email: true
        }
      },
      password: {
        value: '',
        type: 'password',
        label: 'Пароль',
        errorMessage: 'Введите корректный пароль',
        valid: false,
        touched: false,
        validation: {
          required: true,
          minLength: 6
        }
      }
    }
  }

  loginHandler = () => {
    this.props.auth(this.state.formControls.email.value, this.state.formControls.password.value)
  }

  submitHandler = event => {
    event.preventDefault()
  }

  validateControl(value, validation) {
    if (!validation) {
      return true
    }

    let isValid = true

    if (validation.required) {
      isValid = value.trim() !== '' && isValid
    }

    if (validation.email) {
      isValid = is.email(value) && isValid
    }

    if (validation.minLength) {
      isValid = value.length >= validation.minLength && isValid
    }

    return isValid
  }

  onChangeHandler = (event, controlName) => {
    const formControls = { ...this.state.formControls }
    const control = { ...formControls[controlName] }

    control.value = event.target.value
    control.touched = true
    control.valid = this.validateControl(control.value, control.validation)

    formControls[controlName] = control

    let isFormValid = true

    Object.keys(formControls).forEach(name => {
      isFormValid = formControls[name].valid && isFormValid
    })

    this.props.setGeneralErrorMessage(null)

    this.setState({
      formControls, isFormValid
    })
  }

  renderInputs() {
    return Object.keys(this.state.formControls).map((controlName, index) => {
      const control = this.state.formControls[controlName]
      return (
          <Input
              key={controlName + index}
              type={control.type}
              value={control.value}
              valid={control.valid}
              touched={control.touched}
              label={control.label}
              shouldValidate={!!control.validation}
              errorMessage={control.errorMessage}
              onChange={event => this.onChangeHandler(event, controlName)}
          />
      )
    })
  }

  render() {
    return (
        <div className={classes.Auth}>
          <div>
            <h1>Авторизация</h1>

            <form onSubmit={this.submitHandler} className={classes.AuthForm}>

              <div className={classes.AuthGeneralError}>{this.props.generalErrorMessage!==null?this.props.generalErrorMessage:''}</div>

              { this.renderInputs() }

              <Button
                  type="success"
                  onClick={this.loginHandler}
                  disabled={!this.state.isFormValid || this.props.loading}
                  processIconShow={this.props.loading}
              >Войти
              </Button>
            </form>
          </div>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    loading: state.auth.loading,
    generalErrorMessage: state.auth.generalErrorMessage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    auth:(email,password) => dispatch(auth(email,password)),
    setGeneralErrorMessage:(message) => dispatch(setGeneralErrorMessage(message))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Auth)