import {RESTART_SERVICE_BEGIN, RESTART_SERVICE_END} from "../actions/actionTypes"

const initialState = {
    restartingProcess: false
}

export default function controlReducer(state=initialState, action) {
    switch (action.type) {
        case RESTART_SERVICE_BEGIN:
            return {
                ...state, restartingProcess:true
            }
        case RESTART_SERVICE_END:
            return {
                ...state, restartingProcess:false
            }
        default:
            return state
    }
}