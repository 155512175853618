import React, {Component} from 'react'
import classes from './ObjectsList.css'
import Loader from '../../components/UI/Loader/Loader'
import {connect} from "react-redux"
import {fetchObjects} from "../../store/actions/objects"
import FlatButton from "../../components/UI/FlatButton/FlatButton"
import {secondsToPeriod} from "../../App"
import {NavLink} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlusSquare, faExclamationCircle, faDatabase, faNetworkWired } from '@fortawesome/free-solid-svg-icons'

class ObjectsList extends Component {

    state = {
        timer: null
    }

    renderObjects() {
        return this.props.objects.map((obj, index) => {
            const cls = [classes.ObjectItem];

            if(obj.use_sync === 'Y' && obj.pid !== null){
                cls.push(classes.online)
            }
            if(obj.use_sync === 'Y' && obj.pid === null){
                cls.push(classes.offline)
            }
            if(obj.use_sync === 'N'){
                cls.push(classes.disabled)
            }

            const dateStart = obj.pid_start_time ? (new Date(obj.pid_start_time*1000)).toLocaleString() : '---'
            const LastSyncUsers = obj.last_sync_users ? (new Date(obj.last_sync_users*1000)).toLocaleString() : '---'
            const LastSyncEvents = obj.last_sync_events ? (new Date(obj.last_sync_events*1000)).toLocaleString() : '---'

            let tr = null

            if(
                this.props.match.params.type === undefined
                || (this.props.match.params.type === 'broken' && obj.use_sync === 'Y' && obj.pid === null)
                || (this.props.match.params.type === 'online' && obj.use_sync === 'Y' && obj.pid !== null)
                || (this.props.match.params.type === 'disabled' && obj.use_sync === 'N')
            ){
                tr = (
                    <tr key={index} className={cls.join(' ')} onClick={()=>{this.props.history.push({pathname: '/objects/view/'+obj.alias})}}>
                        <td>{obj.alias} ({obj.process_number})</td>
                        <td>{obj.title}</td>
                        <td title={dateStart}>{secondsToPeriod(obj.process_uptime)}</td>
                        <td title={`Пользователи: ${LastSyncUsers} / События: ${LastSyncEvents}`}>{secondsToPeriod(obj.sync_users_timeout)} / {secondsToPeriod(obj.sync_events_timeout)}</td>
                        <td className={classes.objStatus}>
                            {parseInt(obj.overflow)>0?<FontAwesomeIcon icon={faExclamationCircle} title="Переполнение контроллера" />:null}
                            {parseInt(obj.era_db_status_ok) === 0 ? <FontAwesomeIcon icon={faDatabase} title="БД ЭРА недоступна" />:null}
                            {parseInt(obj.in_shkola_api_status_ok) === 0 ? <FontAwesomeIcon icon={faNetworkWired} title="API In-Shkola недоступно" />:null}
                        </td>
                    </tr>
                )
            }

            return (
                tr
            )

        })
    }

    componentDidMount() {
        this.fetchObj()
    }

    fetchObj() {
        this.props.fetchObjects()
        const timer = setTimeout(()=>{this.fetchObj()},5000)
        this.setState({timer})
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer)
    }

    renderFilterButtons() {
        return (<div className={classes.filter}>
            <FlatButton isActive={this.props.match.params.type === undefined} onClick={()=>{this.props.history.push({pathname: '/objects'})}}>Все</FlatButton>
            <FlatButton isActive={this.props.match.params.type === 'online'} onClick={()=>{this.props.history.push({pathname: '/objects/online'})}}>Рабочие</FlatButton>
            <FlatButton isActive={this.props.match.params.type === 'disabled'} onClick={()=>{this.props.history.push({pathname: '/objects/disabled'})}}>Отключенные</FlatButton>
            <FlatButton isActive={this.props.match.params.type === 'broken'} onClick={()=>{this.props.history.push({pathname: '/objects/broken'})}}>Сломанные</FlatButton>
        </div>)
    }

    render() {

        const types = {
            online:'(рабочие)',
            disabled:'(отключенные)',
            broken:'(сломанные)'
        }

        return (
            <div className={classes.ObjectsList}>

                <h1>Список объектов {types[this.props.match.params.type]}</h1>
                <div className={classes.controls}>
                    {this.renderFilterButtons()}
                    <NavLink to="/objects/add" className={classes.addButton}><FontAwesomeIcon icon={faPlusSquare} title="Добавить новый объект"/></NavLink>
                </div>
                {this.props.error !== null ? <div>{this.props.error}</div> : null}
                {
                    this.props.loading && this.props.objects.length === 0
                        ? <Loader/>
                        : <table>
                            <thead>
                                <tr><th>ID (process number)</th><th>Название</th><th>UpTime</th><th>Активность</th><th></th></tr>
                            </thead>
                            <tbody>
                                {this.renderObjects()}
                            </tbody>
                        </table>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        objects: state.objects.objects,
        loading: state.objects.loading,
        error: state.objects.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchObjects: () => dispatch(fetchObjects())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectsList)