import React, {Component} from 'react'
import classes from './Users.css'
import is from "is_js"
import Input from "../../components/UI/Input/Input"
import Button from "../../components/UI/Button/Button"
import API from "../../axios/API"
import Loader from "../../components/UI/Loader/Loader";
import {faPlusSquare,faTrash,faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Users extends Component {

    state = {
        loading: true,
        addDialog: false,
        deleteUser: null,
        deleting: false,
        deleteConfirmEmail: null,
        users: null,
        formIsValid: false,
        formControls: {
            id: {
                value: this.props.user?this.props.user.id:'',
                type: 'hidden',
                valid: true,
            },
            email: {
                value: '',
                type: 'email',
                label: 'Email',
                errorMessage: 'Введите корректный email',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    email: true
                }
            },
            title: {
                value: '',
                type: 'text',
                label: 'Имя',
                errorMessage: 'Введите корректное имя',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 100
                }
            },
            password: {
                value: '',
                type: 'text',
                label: 'Пароль',
                errorMessage: 'Введите корректный пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 6
                }
            }
        }
    }

    componentDidMount() {
        this.fetchUsers()
    }

    onChangeHandler = (event, controlName) => {
        const formControls = { ...this.state.formControls }
        const control = { ...formControls[controlName] }

        control.value = event.target.value
        control.touched = true
        control.valid = this.validateControl(control.value, control.validation)

        formControls[controlName] = control

        let formIsValid = true

        Object.keys(formControls).forEach(name => {
            formIsValid = formControls[name].valid && formIsValid
        })

        this.setState({
            formControls, formIsValid
        })
    }

    validateControl(value, validation) {
        if (!validation) {
            return true
        }

        let isValid = true

        if (validation.required) {
            isValid = value.trim() !== '' && isValid
        }

        if (validation.email) {
            isValid = is.email(value) && isValid
        }

        if (validation.minLength) {
            isValid = value.length >= validation.minLength && isValid
        }

        return isValid
    }

    renderInputs() {
        return Object.keys(this.state.formControls).map((controlName, index) => {
            const control = this.state.formControls[controlName]
            return (
                <Input
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event => this.onChangeHandler(event, controlName)}
                />
            )
        })
    }

    fetchUsers() {
        console.log('fetchUsers')
        this.setState({loading:true})
        API.users().then((responce) => {
            this.setState({loading:false,users:responce.data.data})
        }).catch(error=>{
            this.setState({loading:false})
        })
    }

    addDialogHandler = () => {
        this.setState({addDialog:true})
    }

    saveHandler = () => {
        const controls = this.state.formControls
        this.setState({loading:true})

        API.saveUser(
            controls.id.value
            ,controls.email.value
            ,controls.title.value
            ,controls.password.value
        ).then((responce) => {

            if(responce.data.status === 'ok') {
                this.setState({addDialog:false})
                this.fetchUsers()
            } else {
                alert(responce.data.text)
                this.setState({loading:false, addDialog:false})
            }

        }).catch(error=>{
            if(error.response.status === 404){
                alert('Страница '+ error.response.config.url +' не найдена')
            }
            this.setState({loading:false,addDialog:false})
        })
    }

    deleteHandler(userObj){
        this.setState({deleteUser:userObj})
    }

    onConfirmChangeHandler = (event) => {
        this.setState({
            deleteConfirmEmail:event.target.value
        })
    }

    deleteUser(){

        if(this.state.deleteUser.email !== this.state.deleteConfirmEmail){
            alert('Неверный email подтверждения удаления')
            return
        }

        this.setState({deleting:true})

        API.deleteUser(this.state.deleteUser.id).then((responce)=>{
            this.setState({deleting:false,deleteUser:null})
            if(responce.data.status === 'ok'){
                this.fetchUsers()
            } else {
                alert(responce.data.text)
            }
        }).catch(error=>{
            this.setState({deleting:false,deleteUser:null})
        })
    }

    renderContainer(){

        if(this.state.addDialog){
            return (
                <>
                    {this.renderInputs()}
                    <div className={classes.Controls}>
                        <Button
                            type="success"
                            onClick={this.saveHandler}
                            disabled={!this.state.formIsValid || this.props.loading}
                            processIconShow={this.props.loading}
                        >Добавить
                        </Button>
                    </div>
                </>
            )
        }

        if(this.state.deleteUser !== null){
            return (
                <>
                    <div>Для удаления пользователя {this.state.deleteUser.title} <pre style={{'display':'inline','fontWeight':'bold'}}>({this.state.deleteUser.email})</pre> введите его email</div>
                    <Input onChange={event => this.onConfirmChangeHandler(event)}/>
                    <div className={classes.Controls}>
                        <Button
                            type="success"
                            onClick={()=>{this.deleteUser()}}
                            disabled={this.props.deleting}
                            processIconShow={this.state.deleting}
                        >Удалить
                        </Button>
                        <Button onClick={()=>{this.setState({deleteUser:null})}}>Отмена</Button>
                    </div>
                </>
            )
        }

        const usersTrs = this.state.users.map((obj, index) => {

            return(
                <tr key={index} className={obj.blocked==='1'?classes.blocked:null}>
                    <td>{obj.email}</td>
                    <td>{obj.title}</td>
                    <td>{obj.sessions}</td>
                    <td className={classes.itemControls}>
                        <b><FontAwesomeIcon icon={faPencilAlt} title="Редактировать"/></b>
                        <b onClick={()=>{this.deleteHandler(obj)}}><FontAwesomeIcon icon={faTrash} title="Удалить"/></b>
                    </td>
                </tr>
            )
        })

        return (
            <>
                <table className={classes.listTable}>
                    <thead>
                        <tr><th>Email</th><th>Имя</th><th>Сессий</th><th>Действия</th></tr>
                    </thead>
                    <tbody>
                        {usersTrs}
                    </tbody>
                </table>
                <div className={classes.Controls}>
                    <b className={classes.addButton} onClick={this.addDialogHandler}><FontAwesomeIcon icon={faPlusSquare} title="Добавить пользователя"/></b>
                </div>
            </>
        )
    }

    render() {
        return (
            <div className={classes.Container}>
                <h2>Пользователи</h2>
                {this.state.loading?<Loader/>:this.renderContainer()}
            </div>
        )
    }
}

export default Users