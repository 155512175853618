import React, {Component} from 'react'
import {connect} from 'react-redux'
import classes from './Settings.css'
import PeriodsForm from "../../components/Forms/PeriodsForm/PeriodsForm"
import Users from "../Users/Users";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

class Settings extends Component {

    render() {
        return (
            <>
                <h1>Настройки</h1>

                <Tabs>
                    <TabList className={classes.TabList}>
                        <Tab>Периоды</Tab>
                        <Tab>Пользователи</Tab>
                    </TabList>

                    <TabPanel selectedClassName={classes.TabListPanelSelected}>
                        <PeriodsForm/>
                    </TabPanel>
                    <TabPanel selectedClassName={classes.TabListPanelSelected}>
                        <Users/>
                    </TabPanel>
                </Tabs>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)