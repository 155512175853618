import React, {Component} from 'react'
import classes from './Dashboard.css'
import {NavLink} from 'react-router-dom'
import Loader from '../../components/UI/Loader/Loader'
import {connect} from "react-redux"
import {fetchData} from "../../store/actions/dashboard"
import Plitka from "../../components/UI/Plitka/Plitka";

class Dashboard extends Component {

    state = {
        timer: null
    }

    renderData() {

        return (
            <div className={classes.DashGrid}>
                <NavLink to="/objects">
                    <Plitka type="total" title="всего объектов" value={this.props.total}/>
                </NavLink>
                <NavLink to="/objects/online">
                    <Plitka type={this.props.total-this.props.disabled-this.props.online > 0 ? 'danger' : 'online'} title="объектов работает" value={this.props.online}/>
                </NavLink>
                <NavLink to="/objects/disabled">
                    <Plitka type="disabled" title="объектов отключено" value={this.props.disabled}/>
                </NavLink>
                <NavLink to="/objects/broken">
                    <Plitka type={this.props.offline > 0 ? 'danger' : 'disabled'} title="объектов сломано" value={this.props.offline}/>
                </NavLink>
            </div>
        )
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        this.props.fetchData()
        const timer = setTimeout(()=>{this.fetchData()},5000)
        this.setState({timer})
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer)
    }

    render() {
        return (
            <div className={classes.Dashboard}>
                <h1>Ситуационный центр</h1>
                {this.props.error !== null ? <div>{this.props.error}</div> : null}
                {
                    this.props.total === null
                        ? <Loader/>
                        : this.renderData()
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        total: state.dashboard.total,
        online: state.dashboard.online,
        disabled: state.dashboard.disabled,
        offline: state.dashboard.offline,
        error: state.dashboard.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchData: () => dispatch(fetchData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)