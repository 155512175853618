import React, {Component} from 'react'
import classes from './Control.css'
import Button from "../../components/UI/Button/Button"
import {connect} from "react-redux"
import {serviceRestart} from "../../store/actions/control"


class Control extends Component {

  render() {
    return (
      <div className={classes.Control}>
        <h1>Управление</h1>
        <Button
            onClick={this.props.restartService}
            disabled={this.props.restartingProcess}
            processIconShow={this.props.restartingProcess}
        >Перезапуск службы</Button>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        restartingProcess: state.control.restartingProcess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        restartService: () => dispatch(serviceRestart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Control)