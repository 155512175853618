import {
    FETCH_OBJECT_ERROR,
    FETCH_OBJECT_START,
    FETCH_OBJECT_SUCCESS,
    SET_CONTROLLER_LIMIT
} from "../actions/actionTypes";

const initialState = {
    object: null,
    controllers: null,
    redirect: false,
    error: null
}

export default function objectsReducer(state = initialState, action){
    switch (action.type) {
        case FETCH_OBJECT_START :
            return {
                ...state, object: null, controllers: null, error: null
            }
        case FETCH_OBJECT_SUCCESS :
            return {
                ...state, object: action.data.object, controllers: action.data.controllers
            }
        case FETCH_OBJECT_ERROR :
            return {
                ...state, error: action.error, object: null, controllers: null
            }
        case SET_CONTROLLER_LIMIT :

            const controllers = state.controllers.map((obj)=>{
                if(obj.mac === action.mac){
                    obj.users_limit = action.users_limit
                }
                return obj
            })

            return {
                ...state, controllers
            }
        default:
            return state
    }
}