import React, {Component} from 'react'
import classes from './PeriodsForm.css'
import is from "is_js"
import OnOffSwitcher from "../../UI/OnOffSwitcher/OnOffSwitcher"
import Input from "../../UI/Input/Input"
import Button from "../../../components/UI/Button/Button"
import API from "../../../axios/API"

class PeriodsForm extends Component {

    state = {
        loading: false,
        formIsValid: true,
        confirmDialog: false,
        formControls: {
            users_sync_period: {
                value: this.props.object?this.props.object.users_sync_period:900,
                type: 'number',
                label: 'Период синхронизации пользователей в секундах',
                errorMessage: 'Введите корректный период в секундах',
                valid: true,
                touched: false,
                validation: {
                    required: true
                }
            },
            events_sync_period: {
                value: this.props.object?this.props.object.events_sync_period:30,
                type: 'number',
                label: 'Период синхронизации событий в секундах',
                errorMessage: 'Введите корректный период в секундах',
                valid: true,
                touched: false,
                validation: {
                    required: true
                }
            }
        }
    }

    validateControl(value, validation) {

        if (!validation) {
            return true
        }

        let isValid = true

        if (validation.required) {

            if(typeof value === 'string'){
                isValid = value.trim() !== '' && isValid
            }
        }

        if (validation.email) {
            isValid = is.email(value) && isValid
        }

        if (validation.minLength) {
            isValid = value.length >= validation.minLength && isValid
        }
        if (validation.maxLength) {
            isValid = value.length <= validation.maxLength && isValid
        }
        if (validation.mask) {
            isValid = validation.mask.test(value) && isValid
        }

        return isValid
    }

    onChangeHandler = (event, controlName) => {

        if(event.target.type==='hidden'){
            return
        }

        const formControls = { ...this.state.formControls }
        const control = { ...formControls[controlName] }

        switch (event.target.type) {
            case 'checkbox':
                control.value = event.target.checked
                break
            case 'number':
                control.value = event.target.value.length > 0 ? parseInt(event.target.value):''
                break
            default :
                control.value = event.target.value
        }

        control.touched = true
        control.valid = this.validateControl(control.value, control.validation)

        formControls[controlName] = control

        let formIsValid = true

        Object.keys(formControls).forEach(name => {
            formIsValid = formControls[name].valid && formIsValid
        })

        this.setState({
            formControls, formIsValid
        })
    }

    validateForm(){
        const formControls = { ...this.state.formControls }
        let isFormValid = true
        Object.keys(formControls).forEach(controlName => {
            const control = { ...formControls[controlName] }
            control.value = control.type==='checkbox' ? control.checked : control.value
            control.touched = true
            control.valid = this.validateControl(control.value, control.validation)
            formControls[controlName] = control
            isFormValid = control.valid && isFormValid
        })

        this.setState({
            formControls, isFormValid
        })

        return isFormValid
    }

    renderFields(){
        return Object.keys(this.state.formControls).map((controlName, index) => {
            const control = this.state.formControls[controlName]

            if(control.type === 'onoff'){
                return <OnOffSwitcher
                    key={controlName + index}
                    value={control.value}
                    label={control.label}
                    onChange={event => this.onChangeHandler(event, controlName)}
                />
            }

            return (
                <Input
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event => this.onChangeHandler(event, controlName)}
                />
            )
        })
    }

    saveHandler = () => {
        const controls = this.state.formControls
        this.setState({loading:true})

        API.savePeriods(
            controls.users_sync_period.value
            ,controls.events_sync_period.value
        ).then((responce) => {
            this.setState({loading:false,confirmDialog:false})
            if(responce.data.status !== 'ok') {
                alert(responce.data.text)
            }
        }).catch(error=>{
            if(error.response.status === 404){
                alert('Страница '+ error.response.config.url +' не найдена')
            }
            this.setState({loading:false,confirmDialog:false})
        })
    }

    confirmHandler = () => {
        if(!this.validateForm()){
            return
        }
        this.setState({confirmDialog:true})
    }

    cancelHandler = () => {
        this.setState({confirmDialog:false})
    }

    renderForm(){
        if(this.state.confirmDialog){
            return (
                <div className={classes.ConfirmDialog}>
                    <div>Применение этих настроек приведет к перезапуску всей службы. Продолжить?</div>
                    <div className={classes.ConfirmButtons}>
                        <Button
                            type="success"
                            onClick={this.saveHandler}
                            disabled={!this.state.formIsValid || this.state.loading}
                            processIconShow={this.state.loading}
                        >Продолжить
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.cancelHandler}
                        >Отменить
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <>
                {this.renderFields()}
                <div className={classes.Controls}>
                    <Button
                        type="success"
                        onClick={this.confirmHandler}
                        disabled={!this.state.formIsValid || this.props.loading}
                        processIconShow={this.props.loading}
                    >Сохранить
                    </Button>
                </div>
            </>
        )
    }


    render() {
        return (
            <div className={classes.Container}>
                <h2>Периоды</h2>
                <i>Применится ко всем объектам</i>
                {this.renderForm()}
            </div>
        )
    }
}

export default PeriodsForm