import {
    AUTH_LOGOUT,
    FETCH_OBJECT_ERROR,
    FETCH_OBJECT_START,
    FETCH_OBJECT_SUCCESS, SET_CONTROLLER_LIMIT
} from "./actionTypes";
import API from "../../axios/API";
import {resetLocalStorage} from "./auth"

export function fetchObject(id){
    return async dispatch => {
        dispatch({type: FETCH_OBJECT_START})
        await API.object(id).then((responce)=>{
            dispatch(fetchObjectSuccess(responce.data.data))
        }).catch(error=>{
            dispatch(fetchObjectError(error))
        })
    }
}

export function fetchObjectSuccess(data) {
    return {
        type: FETCH_OBJECT_SUCCESS,
        data
    }
}
export function fetchObjectError(e) {

    if(e.response && e.response.status === 401){
        resetLocalStorage()
        return {
            type: AUTH_LOGOUT
        }
    }
    return {
        type: FETCH_OBJECT_ERROR,
        error: e.response ? e.response.statusText : 'Ошибка подключения'
    }
}
export function setControllerLimit(mac,users_limit) {
    return {
        type: SET_CONTROLLER_LIMIT,
        mac,
        users_limit
    }
}
