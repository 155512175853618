import React from 'react'
import classes from './Button.css'
import spinner from '../../../images/spinner_white.svg'

const Button = props => {
  const cls = [
    classes.Button,
    classes[props.type]
  ]

  return (
    <button
      onClick={props.onClick}
      className={cls.join(' ')}
      disabled={props.disabled}
    >
      <img src={spinner} style={{display:props.processIconShow?"block":"none"}} alt="loading"/>
      <div>{props.children}</div>
    </button>
  )
}

export default Button