import React, {Component} from 'react'
import classes from './ObjectForm.css'
import is from "is_js"
import OnOffSwitcher from "../../UI/OnOffSwitcher/OnOffSwitcher"
import Input from "../../UI/Input/Input"
import Button from "../../../components/UI/Button/Button"

class ObjectForm extends Component {

    state = {
        formIsValid: false,
        formControls: {
            id: {
                value: this.props.object?this.props.object.id:'',
                type: 'hidden',
                valid: true,
            },
            alias: {
                value: this.props.object?this.props.object.alias:'',
                type: 'text',
                label: 'Алиас латинскими без пробелов',
                errorMessage: 'Введите корректный алиас',
                valid: true,
                touched: true,
                validation: {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                    mask:/^[a-z_0-9]+$/
                }
            },
            title: {
                value: this.props.object?this.props.object.title:'',
                type: 'text',
                label: 'Название',
                errorMessage: 'Введите корректное название',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4,
                    maxLength: 150
                }
            },
            db_host: {
                value: this.props.object?this.props.object.db_host:'',
                type: 'text',
                label: 'IP-адрес хоста БД ЭРА',
                errorMessage: 'Введите корректный IP-адрес',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    mask:/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                }
            },
            db_port: {
                value: this.props.object?this.props.object.db_port:3050,
                type: 'text',
                label: 'Порт хоста БД ЭРА',
                errorMessage: 'Введите корректный номер порта',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    mask:/^(\d{2,5})$/
                }
            },
            db_events_path: {
                value: this.props.object?this.props.object.db_events_path:'C:\\Program Files\\ENT\\Server\\DB\\CBASE.FDB',
                type: 'text',
                label: 'Путь к базе событий и пользователей',
                errorMessage: 'Введите корректный путь',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 150
                }
            },
            db_photos_path: {
                value: this.props.object?this.props.object.db_photos_path:'C:\\Program Files\\ENT\\Server\\DB\\GBASE.FDB',
                type: 'text',
                label: 'Путь к базе фотографий',
                errorMessage: 'Введите корректный путь',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 150
                }
            },
            db_user: {
                value: this.props.object?this.props.object.db_user:'SYSDBA',
                type: 'text',
                label: 'Имя пользователя БД ЭРА',
                errorMessage: 'Введите корректное имя',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 2,
                    maxLength: 50
                }
            },
            db_password: {
                value: this.props.object?this.props.object.db_password:'masterkey',
                type: 'text',
                label: 'Пароль пользователя БД ЭРА',
                errorMessage: 'Введите корректный пароль',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 2,
                    maxLength: 50
                }
            },
            in_shkola_username: {
                value: this.props.object?this.props.object.in_shkola_username:'',
                type: 'text',
                label: 'Имя пользователя API InShkola',
                errorMessage: 'Введите корректное имя',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 2,
                    maxLength: 50
                }
            },
            in_shkola_password: {
                value: this.props.object?this.props.object.in_shkola_password:'',
                type: 'text',
                label: 'Пароль пользователя API InShkola',
                errorMessage: 'Введите корректный пароль',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 2,
                    maxLength: 50
                }
            },
            users_sync_period: {
                value: this.props.object?this.props.object.users_sync_period:900,
                type: 'number',
                label: 'Период синхронизации пользователей в секундах',
                errorMessage: 'Введите корректный период в секундах',
                valid: true,
                touched: false,
                validation: {
                    required: true
                }
            },
            events_sync_period: {
                value: this.props.object?this.props.object.events_sync_period:10,
                type: 'number',
                label: 'Период синхронизации событий в секундах',
                errorMessage: 'Введите корректный период в секундах',
                valid: true,
                touched: false,
                validation: {
                    required: true
                }
            },
            controllers_sync_period: {
                value: this.props.object?this.props.object.controllers_sync_period:600,
                type: 'number',
                label: 'Период синхронизации контроллеров в секундах',
                errorMessage: 'Введите корректный период в секундах',
                valid: true,
                touched: false,
                validation: {
                    required: true
                }
            },
            access_schema_name: {
                value: this.props.object?this.props.object.access_schema_name:'inshkola',
                type: 'text',
                label: 'Название схемы доступа для in-shkola',
                errorMessage: 'Введите корректное название схемы',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 150
                }
            },
            last_event_id: {
                value: this.props.object?this.props.object.last_event_id:0,
                type: 'number',
                label: 'ID последнего события (0 - продолжить с последнего события)',
                errorMessage: 'Идентификатор должен быть целым числом',
                valid: true,
                touched: false,
                validation: {
                    required: true
                }
            },
            db_backups_path: {
                value: this.props.object && this.props.object.db_backups_path !== null ? this.props.object.db_backups_path : 'C:\\Program Files\\ENT\\Server\\DB',
                type: 'text',
                label: 'Путь к каталогу создания бэкапов. Если пусто, то бэкап не делается.',
                errorMessage: 'Введите корректный путь',
                valid: true,
                touched: false,
                validation: {
                    required: false,
                    minLength: 5,
                    maxLength: 150
                }
            }
        }
    }

    validateControl(value, validation) {

        if (!validation) {
            return true
        }

        let isValid = true

        if (validation.required) {

            if(typeof value === 'string'){
                isValid = value.trim() !== '' && isValid
            }
        }

        if (validation.email) {
            isValid = is.email(value) && isValid
        }

        if (validation.minLength) {
            isValid = value.length >= validation.minLength && isValid
        }
        if (validation.maxLength) {
            isValid = value.length <= validation.maxLength && isValid
        }
        if (validation.mask) {
            isValid = validation.mask.test(value) && isValid
        }

        return isValid
    }

    onChangeHandler = (event, controlName) => {

        if(event.target.type==='hidden'){
            return
        }

        const formControls = { ...this.state.formControls }
        const control = { ...formControls[controlName] }

        control.value = event.target.type==='checkbox' ? event.target.checked : event.target.value
        control.touched = true
        control.valid = this.validateControl(control.value, control.validation)

        formControls[controlName] = control

        let formIsValid = true

        Object.keys(formControls).forEach(name => {
            formIsValid = formControls[name].valid && formIsValid
        })

        this.setState({
            formControls, formIsValid
        })
    }

    validateForm(){
        const formControls = { ...this.state.formControls }
        let isFormValid = true
        Object.keys(formControls).forEach(controlName => {
            const control = { ...formControls[controlName] }
            control.value = control.type==='checkbox' ? control.checked : control.value
            control.touched = true
            control.valid = this.validateControl(control.value, control.validation)
            formControls[controlName] = control
            isFormValid = control.valid && isFormValid
        })

        this.setState({
            formControls, isFormValid
        })

        return isFormValid
    }

    renderFields(){
        return Object.keys(this.state.formControls).map((controlName, index) => {
            const control = this.state.formControls[controlName]

            if(control.type === 'onoff'){
                return <OnOffSwitcher
                    key={controlName + index}
                    value={control.value}
                    label={control.label}
                    onChange={event => this.onChangeHandler(event, controlName)}
                />
            }

            return (
                <Input
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event => this.onChangeHandler(event, controlName)}
                />
            )
        })
    }

    saveHandler = () => {
        if(!this.validateForm()){
            return
        }
        const controls = this.state.formControls
        this.props.onSaveHandler(controls)
    }

    render() {
        return (
            <div>
                {this.renderFields()}
                <div className={classes.Controls}>
                    <Button
                        type="success"
                        onClick={this.saveHandler}
                        disabled={!this.state.formIsValid || this.props.loading}
                        processIconShow={this.props.loading}
                    >Сохранить
                    </Button>
                </div>
            </div>
        )
    }
}

export default ObjectForm