import API from "../../axios/API"
import {AUTH_LOGOUT, RESTART_SERVICE_BEGIN, RESTART_SERVICE_END} from "./actionTypes"
import {resetLocalStorage} from "./auth";

export function serviceRestart(){

    return async dispatch => {

        dispatch({
            type: RESTART_SERVICE_BEGIN
        })

        await API.serviceRestart().then((responce)=>{
            if(responce.data.status !== 'ok'){
                alert(responce.data.text)
            }
        }).catch(error=>{
            if(error.response && error.response.status === 401){
                resetLocalStorage()
                dispatch({
                    type: AUTH_LOGOUT
                })
            }
        })

        dispatch({
            type: RESTART_SERVICE_END
        })
    }
}
