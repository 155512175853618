import React from 'react'
import classes from './UserPanel.css'
import avatar from '../../../images/avatar.png'
import {NavLink} from 'react-router-dom'
import {connect} from "react-redux"

const UserPanel = props => {

    return (
        <div className={classes.UserPanel}>
            <div className={classes.avatar}><img src={avatar} alt="avatar"/></div>
            <div className={classes.info}>{props.user}</div>
            <ul className={classes.userMenu}>
                <li><NavLink to={'/logout'}>Выйти</NavLink></li>
            </ul>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    }
}

export default connect(mapStateToProps, null)(UserPanel)