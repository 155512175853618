import React, {Component} from 'react'
import classes from './ObjectView.css'
import {connect} from "react-redux"
import {fetchObject, setControllerLimit} from "../../store/actions/object"
import Loader from "../../components/UI/Loader/Loader"
import {secondsToPeriod} from "../../App"
import Button from "../../components/UI/Button/Button"
import API from "../../axios/API"
import Input from "../../components/UI/Input/Input"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import spinner from '../../images/spinner_grey.svg'

class ObjectView extends Component {

    state  = {
        isChangeStatus: false,
        deleting: false,
        needDeleteConfirm: false,
        needProcessRestartConfirm: false,
        deleteConfirmAlias: null,
        editControllerLimit: null,
        isRestartingProcess: false,
        getLogsInfoProcess: false,
        logsInfoData: null,
        getLogContentProcess: false,
        logContent:null
    }

    componentDidMount() {
        this.props.fetchObject(this.props.match.params.alias)
    }

    setObjectStatus(use_sync){
        this.setState({isChangeStatus:true})
        API.setObjectStatus(this.props.object.alias,use_sync).then((responce)=>{
            if(responce.data.status !== 'ok'){
                alert(responce.data.text)
            }
        }).catch(error=>{
        }).finally(()=>{
            this.setState({isChangeStatus:false})
            this.props.fetchObject(this.props.match.params.alias)
        })
    }

    restartProcess(){
        this.setState({isRestartingProcess:true,needProcessRestartConfirm:false})
        API.restartProcess(this.props.object.alias).then((responce)=>{
            this.setState({isRestartingProcess:false})
            if(responce.data.status !== 'ok'){
                alert(responce.data.text)
            } else {
                this.props.history.push('/objects')
            }
        }).catch(error=>{
            this.setState({isRestartingProcess:false})
        })
    }

    getLogsInfo(){
        this.setState({getLogsInfoProcess:true})
        API.getLogsInfo(this.props.object.alias).then((responce)=>{
            this.setState({getLogsInfoProcess:false})
            if(responce.data.status !== 'ok'){
                alert(responce.data.text)
            } else {
                this.setState({logsInfoData:responce.data.data})
            }
        }).catch(error=>{
            this.setState({getLogsInfoProcess:false})
            alert(error.message)
        })
    }

    getLogContent(file){
        this.setState({getLogContentProcess:true,logContent:null})
        API.getLogContent(file).then((responce)=>{
            this.setState({getLogContentProcess:false})
            if(responce.data.status !== 'ok'){
                alert(responce.data.text)
            } else {
                this.setState({logContent:responce.data.data})
            }
        }).catch(error=>{
            this.setState({getLogContentProcess:false})
            alert(error.message)
        })
    }

    deleteObject(){
        if(this.props.match.params.alias !== this.state.deleteConfirmAlias){
            alert('Неверный алиас объекта')
            return
        }
        this.setState({deleting:true})
        API.deleteObject(this.props.match.params.alias).then((responce)=>{
            this.setState({deleting:false,needDeleteConfirm:false})
            this.props.fetchObject(this.props.match.params.alias)
            if(responce.data.status === 'ok'){
                this.props.history.push('/objects')
            } else {
                alert(responce.data.text)
            }
        }).catch(error=>{
            this.setState({deleting:false,needDeleteConfirm:false})
            this.props.fetchObject(this.props.match.params.alias)
        })
    }

    renderFields(){

        const cls = [classes.Container]

        if(this.props.object.use_sync!=='Y') {
            cls.push(classes.disabled)
        }

        return (
            <div className={cls.join(' ')}>
                <table className={classes.FieldsList}>
                    <tbody>
                    <tr><th>Название:</th><td>{this.props.object.title}</td></tr>
                    <tr><th>Алиас:</th><td>{this.props.object.alias}</td></tr>
                    <tr><th>БД ЭРА хост:</th><td>{this.props.object.db_host}</td></tr>
                    <tr><th>БД ЭРА порт:</th><td>{this.props.object.db_port}</td></tr>
                    <tr><th>Путь БД событий ЭРА:</th><td>{this.props.object.db_events_path}</td></tr>
                    <tr><th>Путь БД фото ЭРА:</th><td>{this.props.object.db_photos_path}</td></tr>
                    <tr><th>Пользователь БД ЭРА:</th><td>{this.props.object.db_user}</td></tr>
                    <tr><th>Пароль БД ЭРА:</th><td>{this.props.object.db_password}</td></tr>
                    <tr><th>InShkola API пользователь:</th><td>{this.props.object.in_shkola_username}</td></tr>
                    <tr><th>InShkola API пароль:</th><td>{this.props.object.in_shkola_password}</td></tr>
                    <tr><th>Период синхронизации пользователей:</th><td>{this.props.object.users_sync_period} сек</td></tr>
                    <tr><th>Период синхронизации событий:</th><td>{this.props.object.events_sync_period} сек</td></tr>
                    <tr><th>Период синхронизации контроллеров:</th><td>{this.props.object.controllers_sync_period} сек</td></tr>
                    <tr><th>Схема доступа:</th><td>{this.props.object.access_schema_name}</td></tr>
                    <tr><th>Активно:</th><td>{this.props.object.use_sync==='Y'?'да':'нет'}</td></tr>
                    <tr><th>Порядковый номер запуска процесса в supervisor:</th><td>{this.props.object.process_number}</td></tr>
                    <tr><th>PID:</th><td>{this.props.object.pid}</td></tr>
                    <tr><th>Время запуска:</th><td>{this.props.object.pid_start_time ? (new Date(this.props.object.pid_start_time*1000)).toLocaleString() : '---'} ({secondsToPeriod(this.props.object.process_uptime)})</td></tr>
                    <tr><th>Время последней синхронизации пользователей:</th><td>{this.props.object.last_sync_users ? (new Date(this.props.object.last_sync_users*1000)).toLocaleString() : '---'} ({secondsToPeriod(this.props.object.sync_users_timeout)})</td></tr>
                    <tr><th>Время последней синхронизации событий:</th><td>{this.props.object.last_sync_events ? (new Date(this.props.object.last_sync_events*1000)).toLocaleString() : '---'} ({secondsToPeriod(this.props.object.sync_events_timeout)})</td></tr>
                    <tr><th>Идентификатор последнего события:</th><td>{this.props.object.last_event_id}</td></tr>
                    <tr><th>Путь каталога для создания бэкапов:</th><td>{this.props.object.db_backups_path}</td></tr>
                    </tbody>
                </table>
                {this.renderControlButtons()}
            </div>
        )
    }

    setControllerEditing(mac,val){
        this.setState({editControllerLimit:{mac,val}})
    }

    changeLimit(event,mac){
        this.setState({editControllerLimit:{mac,val:event.target.value}})
    }

    saveLimit(e){
        if (e.key === 'Enter') {
            API.saveControllerLimit(this.state.editControllerLimit.mac,this.state.editControllerLimit.val).then((responce) => {
                if(responce.data.status === 'ok') {
                    this.props.setControllerLimit(this.state.editControllerLimit.mac,this.state.editControllerLimit.val)
                } else {
                    alert(responce.data.text)
                }
                this.setState({editControllerLimit:null})
            }).catch(error=>{
                this.setState({editControllerLimit:null})
            })
        }
    }

    renderControllers() {

        if(this.props.controllers.length === 0){
            return (<div>Контроллеры пока не загружены, подождите актуализацию или перезапустите службу</div>)
        }

        const fields =  this.props.controllers.map((obj, index) => {

            const cls = [];

            if(parseInt(obj.current_users_count) > parseInt(obj.users_limit)){
                cls.push(classes.err)
            }

            return (
                <tr key={index} className={cls.join(' ')}>
                    <td>{obj.mac}</td>
                    <td>{obj.desc}</td>
                    {
                        this.state.editControllerLimit !== null && this.state.editControllerLimit.mac === obj.mac
                        ?<td><input
                                type="number"
                                value={this.state.editControllerLimit.val}
                                onKeyUp={(e)=>{this.saveLimit(e, obj.mac, this.state.editControllerLimit.val)}}
                                onChange={event => this.changeLimit(event, obj.mac)}/></td>
                        :<td className={classes.cLimit} onClick={()=>{this.setControllerEditing(obj.mac,obj.users_limit)}}>{obj.users_limit} <FontAwesomeIcon icon={faPencilAlt} /></td>
                    }
                    <td>{obj.current_users_count}</td>
                </tr>
            )
        })

        return (
            <table className={classes.controllers}>
                <thead>
                    <tr>
                        <th>MAC</th>
                        <th>Описание</th>
                        <th>Лимит ключей</th>
                        <th>Ключей загружено</th>
                    </tr>
                </thead>
                <tbody>{fields}</tbody>
            </table>
        )
    }

    onDeleteHandler(){
        this.setState({deleteConfirmAlias:null,needDeleteConfirm:true})
    }
    onProcessRestartHandler(){
        this.setState({needProcessRestartConfirm:true})
    }

    onGetLogsInfoHandler(){
        this.getLogsInfo()
    }

    renderControlButtons(){

        const controlButtons = {
            disable: {
                onClick: ()=>{this.setObjectStatus(this.props.object.use_sync !== 'Y')},
                    processIconShow: this.state.isChangeStatus,
                    disabled: this.state.isChangeStatus,
                    text: this.props.object.use_sync==='Y'?'Отключить':'Включить'
            },
            edit: {
                onClick: ()=>{this.props.history.push({pathname: '/objects/edit'})},
                disabled: this.props.object.use_sync === 'Y',
                text: 'Редактировать'
            },
            delete: {
                onClick: ()=>{this.onDeleteHandler()},
                disabled: this.props.object.use_sync === 'Y',
                text: 'Удалить'
            },
            restart: {
                onClick: ()=>{this.onProcessRestartHandler()},
                disabled: this.props.object.use_sync !== 'Y' || this.state.isRestartingProcess,
                processIconShow: this.state.isRestartingProcess,
                text: this.state.isRestartingProcess?'Идет перезапуск':'Перезапустить'
            },
            logs: {
                onClick: ()=>{this.onGetLogsInfoHandler()},
                processIconShow: this.state.getLogsInfoProcess,
                disabled: this.state.getLogsInfoProcess,
                text: 'Логи'
            }
        }

        let controlButtonsHtml = Object.keys(controlButtons).map((buttonName, index) => {
            const button = controlButtons[buttonName]
            return (
                <Button key={buttonName + index} onClick={button.onClick} disabled={button.disabled} processIconShow={button.processIconShow}>{button.text}</Button>
            )
        })

        return (
            <div className={classes.Controls}>
                {controlButtonsHtml}
            </div>
        )
    }

    onConfirmChangeHandler = (event) => {
        this.setState({
            deleteConfirmAlias:event.target.value
        })
    }

    renderDeleteConfirmForm(){
        return (
            <div className={classes.deleteConfirmForm}>
                <h1>Для удаление объекта {this.props.object.title} ({this.props.object.alias}) введите его алиас</h1>
                <Input onChange={event => this.onConfirmChangeHandler(event)}/>
                <div className={classes.deleteConfirmFormControls}>
                    <Button
                        onClick={()=>{this.deleteObject()}}
                        processIconShow={this.state.deleting}
                    >Удалить</Button>
                    <Button onClick={()=>{this.setState({needDeleteConfirm:false})}}>Отмена</Button>
                </div>
            </div>
        )
    }

    renderProcessRestartConfirmForm(){
        return (
            <div className={classes.deleteConfirmForm}>
                <h1>Вы действительно хотите перезапустить процесс<br/>{this.props.object.title} ({this.props.object.alias})?</h1>
                <div className={classes.deleteConfirmFormControls}>
                    <Button
                        onClick={()=>{this.restartProcess()}}
                    >Да, перезапустить</Button>
                    <Button onClick={()=>{this.setState({needProcessRestartConfirm:false})}}>Отмена</Button>
                </div>
            </div>
        )
    }

    renderLogsInfoForm(){

        let logs = Object.keys(this.state.logsInfoData).map((logName, index) => {
            const log = this.state.logsInfoData[logName]
            let size = log.size;
            if(size > 1048576){
                size = Math.round(size/1048576*10)/10 + ' МБ'
            } else if(size > 1024){
                size = Math.round(size/1024*10)/10 + ' кБ'
            } else {
                size += ' Б'
            }

            return (
                <tr key={logName + index}>
                    <td>{logName}</td>
                    <td>{size}</td>
                    <td>{log.mtime}</td>
                    <td><Button
                        onClick={()=>this.getLogContent(logName)}
                    >Открыть</Button></td>
                </tr>
            )
        })

        let logContent = (<img src={spinner} style={{display:this.state.getLogContentProcess?"block":"none"}} alt="loading"/>)
        if(this.state.logContent){
            logContent = this.state.logContent.split('\n').map((item, i) => {
                return <p key={i}>{item}</p>;
            });
        }


        return (
            <div >
                <h1>Логи {this.props.object.title} ({this.props.object.alias})</h1>
                <table className={classes.logsTable}>
                    <thead>
                    <tr><th>Файл</th><th>Размер</th><th>Изменен</th><th>Действие</th></tr>
                    </thead>
                    <tbody>{logs}</tbody>
                </table>
                <div className={classes.logsControls}>
                    <Button onClick={()=>{this.setState({logsInfoData:null,logContent:null})}}>Закрыть</Button>
                </div>
                <div className={classes.logContent}>{logContent}</div>
            </div>
        )
    }

    render() {

        if(this.state.needDeleteConfirm){
            return this.renderDeleteConfirmForm()
        }
        if(this.state.needProcessRestartConfirm){
            return this.renderProcessRestartConfirmForm()
        }

        if(this.state.logsInfoData !==null){
            return this.renderLogsInfoForm()
        }

        if(this.props.error !== null) {
            return (<div>{this.props.error}</div>)
        }

        if(this.props.object === null){
            return (<Loader/>)
        }

        return (
            <div>
                <h1>{this.props.object.title}</h1>
                {this.renderFields()}
                <h2>Контроллеры</h2>
                <div style={{marginTop:'-18px'}}>(синхронизация {this.props.object.last_sync_controllers_list ? (new Date(this.props.object.last_sync_controllers_list*1000)).toLocaleString() : '---'} ({secondsToPeriod(this.props.object.last_sync_controllers_list_timeout)}))</div>
                {this.renderControllers()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        object: state.object.object,
        controllers: state.object.controllers,
        error: state.object.error,
        loading: state.object.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchObject: (id) => dispatch(fetchObject(id)),
        setControllerLimit: (mac,val) => dispatch(setControllerLimit(mac,val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectView)