import {SET_LOGGED_IN, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_ERROR, AUTH_BEGIN} from '../actions/actionTypes'
const initialState = {
    loggedIn: false,
    loading: false,
    generalErrorMessage: null,
    user: null
}

export default function authReducer(state=initialState, action) {
    switch (action.type) {
        case AUTH_BEGIN:
            return {
                ...state, loading:true
            }
        case AUTH_SUCCESS:
            return {
                ...state, loggedIn:true, user: action.user
            }
        case SET_LOGGED_IN:
            state.loggedIn = action.value
            return state
        case AUTH_LOGOUT :
            return {
                ...state, loading: false, loggedIn: false
            }
        case AUTH_ERROR :
            return {
                ...state, loading: false, loggedIn: false, generalErrorMessage:action.errorMessage
            }
        default:
            return state
    }
}