import React from 'react'
import classes from './Plitka.css'

const Plitka = props => {
  const cls = [classes.Plitka, classes[props.type]]
  return (
    <div
      className={cls.join(' ')}
    >
      <div className={classes.title}>{props.title}</div>
      <div className={classes.value}>{props.value}</div>
    </div>
  )
}

export default Plitka