import {AUTH_LOGOUT, FETCH_OBJECTS_ERROR, FETCH_OBJECTS_START, FETCH_OBJECTS_SUCCESS} from "./actionTypes"
import API from "../../axios/API"
import {resetLocalStorage} from "./auth"

export function fetchObjects(){

    return async dispatch => {

        dispatch(fetchObjectsStart())

        await API.objects().then((responce)=>{
            dispatch(fetchObjectsSuccess(responce.data.data))
        }).catch(error=>{
            dispatch(fetchObjectsError(error))
        })
    }
}

export function fetchObjectsStart() {
    return {
        type: FETCH_OBJECTS_START
    }
}
export function fetchObjectsSuccess(objects) {
    return {
        type: FETCH_OBJECTS_SUCCESS,
        objects
    }
}
export function fetchObjectsError(e) {
    if(e.response && e.response.status === 401){
        resetLocalStorage()
        return {
            type: AUTH_LOGOUT
        }
    }
    return {
        type: FETCH_OBJECTS_ERROR,
        error: e.response ? e.response.statusText : 'Ошибка подключения'
    }
}