import React, {Component} from 'react'
import classes from './Layout.css'
import UserPanel from "../../components/UI/UserPanel/UserPanel"
import Navigation from "../../components/UI/Navigation/Navigation"

class Layout extends Component {
    render() {
        return (
            <div className={classes.Layout}>
                <div className={classes.header}>
                    <div className={classes.hcontent}>
                        <Navigation/>
                        <UserPanel/>
                    </div>
                </div>
                <main>
                    { this.props.children }
                </main>
                <div className={classes.footer}>Панель управления «МегаМодуль»</div>
            </div>
        )
    }
}

export default Layout