import {DASHBOARD_ERROR, DASHBOARD_SUCCESS} from "../actions/actionTypes";

const initialState = {
    total: null,
    online: null,
    disabled: null,
    offline: null,
    error: null
}

export default function dashboardReducer(state = initialState, action){
    switch (action.type) {
        case DASHBOARD_SUCCESS :
            return {
                ...state, total: action.data.total, online: action.data.online, disabled: action.data.disabled, offline: action.data.offline
            }
        case DASHBOARD_ERROR :
            return {
                ...state, error: action.error
            }
        default:
            return state
    }
}