import {combineReducers} from 'redux'
import authReducer from "./auth"
import objectsReducer from './objects'
import objectReducer from './object'
import dashboardReducer from './dashboard'
import controlReducer from "./control"

export default combineReducers({
    auth: authReducer,
    objects: objectsReducer,
    dashboard: dashboardReducer,
    control: controlReducer,
    object: objectReducer
})