import {AUTH_BEGIN, AUTH_ERROR, AUTH_LOGOUT, AUTH_SUCCESS} from './actionTypes'
import API from "../../axios/API";

export function auth(email,password){
    localStorage.setItem('email', email)

    return async dispatch => {

        dispatch({
            type: AUTH_BEGIN
        })

        await API.auth(email,password).then((responce) => {
            if(responce.data.status === 'ok') {
                const token = responce.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('user', responce.data.user)
                dispatch({
                    type: AUTH_SUCCESS,
                    user: responce.data.user
                })
            } else {
                dispatch(authError(responce.data.text))
            }

        }).catch(error=>{
            dispatch(authError('Ошибка подключения'))
        }).finally(()=>{

        })
    }
}

export function authLogout(){
    return dispatch => {

        API.logout().then((responce)=>{
        }).catch(error=>{
        })

        resetLocalStorage()
        dispatch({
            type: AUTH_LOGOUT
        })
    }
}

export function resetLocalStorage() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
}

function authError(errorMessage){
    return {
        type: AUTH_ERROR,
        errorMessage
    }
}
export function autoLogin(){
    return dispatch => {
        const token = localStorage.getItem('token')
        if(!token){
            dispatch({
                type: AUTH_LOGOUT
            })
        } else {
            dispatch({
                type: AUTH_SUCCESS,
                user: localStorage.getItem('user')
            })
        }
    }
}
export function setGeneralErrorMessage(message){
    return {
        type: AUTH_ERROR,
        errorMessage:message
    }
}