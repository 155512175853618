import React, {Component} from 'react'
import AuthLayout from "./hoc/AuthLayout/AuthLayout"
import Layout from './hoc/Layout/Layout'
import {Route, Switch, Redirect} from 'react-router-dom'
import Auth from './containers/Auth/Auth'
import ObjectsList from "./containers/ObjectsList/ObjectsList"
import classes from './App.css'
import {connect} from "react-redux"
import {autoLogin} from "./store/actions/auth"
import Logout from "./components/Logout/Logout"
import Dashboard from "./containers/Dashboard/Dashboard"
import { withRouter } from 'react-router'
import Control from "./containers/Control/Control"
import ObjectView from "./containers/ObjectView/ObjectView"
import ObjectAdd from "./containers/ObjectAddEdit/ObjectAdd"
import ObjectEdit from "./containers/ObjectAddEdit/ObjectEdit"
import Settings from "./containers/Settings/Settings"

class App extends Component {

    componentDidMount() {
        this.props.authLogin()
    }

    render() {

        let routes = (
            <AuthLayout>
                <Switch>
                    <Route component={Auth} />
                </Switch>
            </AuthLayout>
        )

        if(this.props.isAuth){
            routes = (
                <Layout>
                    <Switch>
                        <Route path="/objects/view/:alias" exact component={ObjectView} />
                        <Route path="/objects/add" exact component={ObjectAdd} />
                        <Route path="/objects/edit" exact component={ObjectEdit} />
                        <Route path="/objects/:type" exact component={ObjectsList} />
                        <Route path="/objects" exact component={ObjectsList} />
                        <Route path="/settings" exact component={Settings} />
                        <Route path="/control" exact component={Control} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/" exact component={Dashboard} />
                        <Redirect to={'/'}/>
                    </Switch>
                </Layout>
            )
        }

        return (
            <div className={classes.App}>
                {routes}
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        isAuth: state.auth.loggedIn
    }
}

function mapDispatchToProps(dispatch){
    return {
        authLogin: () => dispatch(autoLogin())
    }
}

export default  connect(mapStateToProps,mapDispatchToProps)(withRouter(App))

/**
 * Секунды в человеческий интервал
 * @param seconds
 * @returns {string}
 */
export function secondsToPeriod(seconds){
    if(seconds === null){
        return 'down';
    }
    const to = parseInt(seconds);

    if (to >= 60 * 60 * 24)
        return Math.ceil(to / 60 / 60 / 24) + ' дн';
    if (to >= 60 * 60)
        return Math.ceil(to / 60 / 60) + ' ч';
    if (to >= 60)
        return Math.ceil(to / 60) + ' мин';
    return to + ' c';
}