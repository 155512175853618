import {
    AUTH_LOGOUT, DASHBOARD_ERROR,
    DASHBOARD_SUCCESS
} from "./actionTypes";
import {authLogout} from "./auth";
import API from "../../axios/API";

export function fetchData(){

    return async dispatch => {

        await API.stat().then((responce)=>{
            dispatch(fetchDataSuccess(responce.data.data))
        }).catch(error=>{
            dispatch(fetchDataError(error))
        })
    }
}

export function fetchDataSuccess(data) {
    return {
        type: DASHBOARD_SUCCESS,
        data
    }
}

export function fetchDataError(e) {
    if(e.response && e.response.status === 401){
        authLogout()
        return {
            type: AUTH_LOGOUT
        }
    }
    return {
        type: DASHBOARD_ERROR,
        error: e.response ? e.response.statusText : 'Ошибка подключения'
    }
}