import React,{Component} from 'react'
import {connect} from "react-redux"
import {authLogout} from "../../store/actions/auth"
import {Redirect} from "react-router-dom"

class Logout extends Component {
    render (){
        this.props.logout()
        return <Redirect to={'/'} />
    }
}

function mapDispatchToProps(dispatch){
    return {
        logout: () => dispatch(authLogout())
    }
}

export default connect(null,mapDispatchToProps)(Logout)