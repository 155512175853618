import React, {Component} from 'react'

import API from "../../axios/API"
import ObjectForm from "../../components/Forms/ObjectForm/ObjectForm";
import {connect} from "react-redux";
import {Redirect} from "react-router"

class ObjectEdit extends Component {

    state = {
        loading: false
    }

    saveEdObject = (controls) => {

        this.setState({loading:true})

         API.saveObject(
            controls.id.value
            ,controls.alias.value
            ,controls.title.value
            ,controls.db_host.value
            ,controls.db_port.value
            ,controls.db_events_path.value
            ,controls.db_photos_path.value
            ,controls.db_backups_path.value
            ,controls.db_user.value
            ,controls.db_password.value
            ,controls.in_shkola_username.value
            ,controls.in_shkola_password.value
            ,controls.users_sync_period.value
            ,controls.events_sync_period.value
            ,controls.access_schema_name.value
            ,controls.last_event_id.value
            ,controls.controllers_sync_period.value
        ).then((responce) => {
			this.setState({loading:false})
            if(responce.data.status === 'ok') {
                this.props.history.push('/objects/view/'+controls.alias.value)
            } else {
                alert(responce.data.text)
            }
        }).catch(error=>{
			this.setState({loading:false})
        })
    }

    render() {

        if(!this.props.object){
            return (<Redirect to="/objects"/>)
        }

        return (
            <>
                <h1>Редактирование объекта {this.props.object.title}</h1>
                <ObjectForm
                    onSaveHandler={(controls)=>{this.saveEdObject(controls)}}
                    loading={this.state.loading}
                    object={this.props.object}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        object: state.object.object
    }
}
export default connect(mapStateToProps, null)(ObjectEdit)