import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://api.megamodule.anart.ru'
    //baseURL: 'http://api.megamodule.local/'
});

class API {

    async auth(email, password) {
        return instance.post(`/auth`,{email, password}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    /**
     * Получение списка объектов
     * @returns {Promise<AxiosResponse<T>>}
     */
    async objects() {
        return instance.get(`/objects`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    /**
     * Получение информации об объекте
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    async object(id) {
        return instance.get(`/objects/view/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    async stat() {
        return instance.get(`/stat`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    logout() {
        return instance.get(`/logout`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    /**
     * Перезагрузка сервиса
     * @returns {Promise<AxiosResponse<T>>}
     */
    async serviceRestart() {
        return instance.get(`/service/restart`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async saveObject(id,alias,title,db_host,db_port,db_events_path,db_photos_path,db_backups_path,db_user,db_password,in_shkola_username,in_shkola_password,users_sync_period,events_sync_period,access_schema_name,last_event_id,controllers_sync_period) {
        return instance.post(`/objects/save`,{id,alias,title,db_host,db_port,db_events_path,db_photos_path,db_backups_path,db_user,db_password,in_shkola_username,in_shkola_password,users_sync_period,events_sync_period,access_schema_name,last_event_id,controllers_sync_period}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async setObjectStatus(alias,use_sync) {
        return instance.post(`/objects/setStatus`,{alias,use_sync:(use_sync===true?'Y':'N')}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async restartProcess(alias) {
        return instance.post(`/objects/restartProcess`,{alias}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async getLogsInfo(alias) {
        return instance.get(`/objects/logsInfo/${alias}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async getLogContent(file) {
        return instance.post(`/objects/logContent`,{file}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async deleteObject(alias) {
        return instance.post(`/objects/delete`,{alias}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async saveControllerLimit(mac,value) {
        return instance.post(`/objects/setControllerLimit`,{mac,value}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async savePeriods(users_sync_period,events_sync_period) {
        return instance.post(`/objects/save-periods`,{users_sync_period,events_sync_period}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async users() {
        return instance.get(`/users`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async saveUser(id,email,title,password) {
        return instance.post(`/users/save`,{id,email,title,password}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async deleteUser(id) {
        return instance.post(`/users/delete`,{id}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

}

export default new API()