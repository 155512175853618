import React, {Component} from 'react'
import classes from './AuthLayout.css'

class AuthLayout extends Component {
    render() {
        return (
            <div className={classes.Layout}>
                { this.props.children }
                <div className={classes.footer}>Панель управления «Мегамодуль» | Версия: 1.0.0</div>
            </div>
        )
    }
}

export default AuthLayout